import { render, staticRenderFns } from "./ProbableStudentDetails.vue?vue&type=template&id=514a60fd&scoped=true&"
import script from "./ProbableStudentDetails.vue?vue&type=script&lang=js&"
export * from "./ProbableStudentDetails.vue?vue&type=script&lang=js&"
import style0 from "./ProbableStudentDetails.vue?vue&type=style&index=0&id=514a60fd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514a60fd",
  null
  
)

export default component.exports